import { Outlet } from "react-router-dom";
// @mui
import { Box, Stack } from "@mui/material";

import { lazy, Suspense } from "react";
import LoadingSkeleton from "src/components/LoadingSkeleton";

const MainHeader = lazy(() => import("./MainHeader"));
const MainFooter = lazy(() => import("./MainFooter"));

// ----------------------------------------------------------------------
/* 
const useStyles = makeStyles((theme) => ({
  containerLogo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  containerCopyright: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
})); */

export default function MainLayout() {
  return (
    <Stack sx={{ minHeight: 1 }}>
      <Suspense fallback={<LoadingSkeleton />}>
        <MainHeader />
      </Suspense>

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      <Suspense fallback={<LoadingSkeleton />}>
        <MainFooter />
      </Suspense>

      {/* {!isHome ? (
        <MainFooter />
      ) : (
        <Container>
          <Box
            sx={{
              py: 5,
              position: "relative",
              bgcolor: "background.default",
            }}
          >
            <Grid
              container
              spacing={3}
              style={{ minHeight: "15vh" }}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6} className={classes.containerLogo}>
                <Logo />
              </Grid>
              <Grid item xs={12} md={6} className={classes.containerCopyright}>
                <Typography
                  variant="body"
                  component="p"
                  sx={{ mx: "auto", color: "text.secondary" }}
                >
                  {t("copyright", {
                    year: `© ${moment(new Date()).format("YYYY")}`,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )} */}
    </Stack>
  );
}
