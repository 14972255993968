import { Skeleton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingSkeleton() {
  return (
    <>
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"75vh"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Skeleton>
    </>
  );
}
